import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Event from "../components/event";
import styled from "styled-components";
import Cta from "../components/cta";
import Button from "../components/button";

const Day = styled.section``;

const SchedulePage = () => (
  <Layout backgroundColor="#F5F2E6">
    <Seo title="Schedule" />
    <h1>Schedule</h1>

    <Day className="day">
      <h2>
        <time dateTime="2021-10-01">Friday, October 1</time>
      </h2>

      <Event time="5–7pm" title="Welcome Party">
        <p>
          We're hosting a kid-friendly welcome party for our traveling guests.
          The location will be the Stow Lake Boathouse picnic area in Golden
          Gate Park. The party will be catered and outdoors so bring layers!
          Limited parking is available by the boathouse. Come early and rent paddle boats with the family or stay late for <a href="https://www.eventbrite.com/e/lakehouse-jazz-tickets-74499918261">Lakehouse Jazz</a> at the boathouse.
        </p>

        <p>87 Stow Lake Drive</p>

        <p>
          <Button
            text="Apple Maps"
            url="https://maps.apple.com/?address=Golden%20Gate%20Park,%2087%E2%80%9389%20Stow%20Lake%20Dr,%20San%20Francisco,%20CA%20%2094118,%20United%20States&ll=37.770865,-122.477315&q=Marked%20Location&_ext=EiYpkJ98ShXiQkAx6SYjx+ieXsA5DnWipjvjQkBBfXyWlS6eXsBQBA%3D%3D"
          />
          <Button
            text="Google Maps"
            url="https://www.google.com/maps/place/87+Stow+Lake+Dr,+San+Francisco,+CA+94118/@37.7707798,-122.47929,17z/data=!3m1!4b1!4m5!3m4!1s0x8085876c5739afc3:0xd82a177cc4de68cb!8m2!3d37.7707756!4d-122.477096"
          />
        </p>
      </Event>
    </Day>

    <Day className="day">
      <h2>
        <time dateTime="2021-10-02">Saturday, October 2</time>
      </h2>

      <Event time="4:30pm" title="Ceremony">
        <p>
          The ceremony will take place outdoors facing the Bay at The Firehouse
          at Fort Mason.
          <address>
            2 Marina Blvd. <br />
            San Francisco, CA
          </address>
        </p>
      </Event>

      <Event time="5pm" title="Cocktail Hour">
        <p>
          Join us for outdoor signature cocktails, appetizers, and music.
        </p>
      </Event>

      <Event time="6pm" title="Dinner">
        <p>
          Dinner will be served buffet-style indoors. The food is being provided
          by <a href="https://www.nopalitosf.com">Nopalito,</a> a renowned
          Mexican restaurant. Please let us know if anyone in your family has
          any dietary restrictions.
        </p>
      </Event>

      <Event time="7:30–10pm" title="Dancing">
        <p>
          After dinner we’ll dance the night away under the stars. Karaoke is a go!
        </p>
      </Event>

      <Event time="10–11pm" title="After party">
        <p>
          If you still have the stamina join us for cocktails at <a href="https://theinterval.org/about/">The Interval at Long Now</a>, founded by Stewart Brand of The Whole Earth Catalog fame. The bar is located within the Fort Mason campus, a short walk from The Firehouse.
        </p>

      </Event>
    </Day>

    <Day className="day">
      <h2>
        <time dateTime="2021-10-03">Sunday, October 3</time>
      </h2>
      <Event time="10am–1pm" title="Farewell Brunch">
        <p>
          We’ll have bagels, lox, and pastries at the 45th Avenue Playground in
          Golden Gate Park. There will also be lawn games! You’re free to drop
          in. Street parking is available on Lincoln Way.
        </p>

        <p>
          4414 Lincoln Way
        </p>

        <p>
          <Button
            text="Apple Maps"
            url="https://maps.apple.com/?address=4414%20Lincoln%20Way,%20San%20Francisco,%20CA%20%2094122,%20United%20States&auid=2328596662719300309&ll=37.764798,-122.505030&lsp=9902&q=45th%20Avenue%20Playground&_ext=CjMKBQgEEOIBCgQIBRADCgUIBhC9AgoECAoQAAoECFIQBwoECFUQDwoECFkQAQoFCKQBEAESJik7IwMUQOFCQDHbdhNys6BewDm5+ChwZuJCQEFnZLhE+Z9ewFAE"
          />
          <Button
            text="Google Maps"
            url="https://www.google.com/maps/place/Golden+Gate+Park+45th+Avenue+Playground/@37.76484,-122.507228,17z/data=!3m1!4b1!4m5!3m4!1s0x80858797df5cd20b:0xf79a5c1c2cbc36de!8m2!3d37.76484!4d-122.5050393"
          />
        </p>
      </Event>
    </Day>
    <Cta url="/transportation" text="Explore transportation" />
  </Layout>
);

export default SchedulePage;
