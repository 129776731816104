import * as React from "react"
import styled from "styled-components"


const SingleEvent = styled.div`

    margin-bottom:1em;

    h3{
        margin-bottom:0;
    }

    address{
        margin-left:0;
        margin-top:1em;
    }
    
`

export default function Event({time, title,children}){
    return(
        <SingleEvent>
            <h3>{time} – {title}</h3>
            <div className="content">
                {children}
            </div>
        </SingleEvent>
    )
}